@font-face {
  font-family: "Output Sans Regular";
  src: url("https://db.onlinewebfonts.com/t/5c442d1d819b8d9a7edc112c2665ef93.eot");
  src: url("https://db.onlinewebfonts.com/t/5c442d1d819b8d9a7edc112c2665ef93.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/5c442d1d819b8d9a7edc112c2665ef93.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/5c442d1d819b8d9a7edc112c2665ef93.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/5c442d1d819b8d9a7edc112c2665ef93.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/5c442d1d819b8d9a7edc112c2665ef93.svg#Output Sans Regular")
      format("svg");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* src/styles.css */
.video-container {
  position: fixed; /* Cambia da 'relative' a 'fixed' per coprire l'intero viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; /* Assicurati che il contenitore sia dietro agli altri elementi */
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
}

.overlay-blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20;
  z-index: 1; /* Posiziona l'overlay sopra il video */
}

.center-text {
  font-family: "Output Sans Regular";
  color: white;
  font-size: 3rem;
  text-align: center;
  font-size: clamp(2.0rem, 1.662vw + 1.0035rem, 3.25rem);
  z-index: 2; /* Posiziona il testo sopra l'overlay */
}